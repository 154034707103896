<template>
  <div class="app-project-risk-detail" v-if="detail">
    <div class="padding-08">
      <div class="padding-08 fc-t bold">
        <span>
          {{detail.projectName}}
          <template v-if="detail.stageName">&nbsp;/ {{detail.stageName}}</template>
          <template v-if="detail.taskName">&nbsp;/ {{detail.taskName}}</template>
        </span>
        <br />
        <span class="fs-huge">风险记录详情</span>
      </div>
      <div class="padding-08">{{ detail.remark }}</div>
    </div>
    <div class="padding-08 bc-w" v-if="detail.status === 'CLOSE'">
      <div class="padding-08">
        <div class="fs-small bold fc-s">风险处置</div>
        <div class="gap-10">{{detail.dealInfo}}</div>
      </div>
    </div>
    <van-cell-group title="记录信息">
      <van-cell title="当前状态">
        <van-tag :type="riskStatusEnum[detail.status]?.type">{{ riskStatusEnum[detail.status].name }}</van-tag>
      </van-cell>
      <van-cell title="来源类型">
        <div class="gap-l-small">{{detail.sourceType === "TASK" ? '任务' : detail.sourceType === "STAGE" ? '阶段' : '项目'}}</div>
      </van-cell>
      <van-cell title="风险等级">
        <van-tag type="danger">{{detail.riskLevelName}}</van-tag>
      </van-cell>
      <van-cell title="风险类型">
        <van-tag type="default">{{detail.riskTypeName}}</van-tag>
      </van-cell>
      <van-cell title="记录人" :value="detail.createBy" />
      <van-cell title="记录时间" :value="detail.begTime" />
      <template v-if="detail.status === 'CLOSE'">
        <van-cell title="处置人" :value="detail.dealUserName" />
        <van-cell title="处置时间" :value="detail.endTime" />
      </template>
    </van-cell-group>
  </div>
</template>

<script>
import { getById, getDictDetail } from "@/api/project/risk";
import { riskStatusEnum } from "@/enums/index";

export default {
  computed: {
    riskId() {
      return this.$route.params.riskId;
    },
  },
  data() {
    return {
      detail: null,
      riskStatusEnum,
      riskLevels: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      Promise.all([
        getDictDetail({
          dictName: "risk_level",
          page: 0,
          size: 9999,
        }),
        getById(this.riskId),
      ])
        .then((arr) => {
          this.riskLevels = arr[0].content;
          const detail = arr[1];
          const l = this.riskLevels.find((o) => o.id === detail.riskLeve);
          if (l) detail.riskLevelName = l.label;
          this.detail = detail;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>